<template>
  <div
    v-if="hasError"
    class="h-screen flex justify-center items-center bg-[#002349]"
  >
    <div
      class="w-full md:w-min h-max font-opensans py-5 px-3 bg-[#880808] md:p-10 border-2 rounded-lg mx-3 md:mx-0 z-10 shadow-xl"
    >
      <span>{{ error }}</span>
    </div>
  </div>
  <div class="h-screen flex justify-center items-center bg-[#002349]">
    <div
      class="w-full md:w-min h-max font-opensans py-5 px-3 bg-[#F4F4F4] md:p-10 border-2 rounded-lg mx-3 md:mx-0 z-10 shadow-xl"
    >
      <form autocomplete="off">
        <div class="cols relative">
          <label
            for="email"
            class="text-[#002349] text-xl md:text-3xl font-opensans_semibold"
            >Email:</label
          >
          <svg
            class="svg-1 absolute hidden md:block md:h-7"
            fill="#000000"
            height="64px"
            width="64px"
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 490.4 490.4"
            xml:space="preserve"
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              <g>
                <path
                  d="M484.1,454.796l-110.5-110.6c29.8-36.3,47.6-82.8,47.6-133.4c0-116.3-94.3-210.6-210.6-210.6S0,94.496,0,210.796 s94.3,210.6,210.6,210.6c50.8,0,97.4-18,133.8-48l110.5,110.5c12.9,11.8,25,4.2,29.2,0C492.5,475.596,492.5,463.096,484.1,454.796z M41.1,210.796c0-93.6,75.9-169.5,169.5-169.5s169.6,75.9,169.6,169.5s-75.9,169.5-169.5,169.5S41.1,304.396,41.1,210.796z"
                ></path>
              </g>
            </g>
          </svg>
          <div class="w-full">
            <input
              type="email"
              id="email"
              class="w-full text-xl focus:outline-none bg-[#F4F4F4] focus:bg-[#FFFFFF] border-[3px] border-[#979797] rounded h-10 px-5 md:pl-16 my-4 md:h-16 hover:shadow-md"
              placeholder="email@beispiel.de"
              v-model="searchEmail"
              required
            />
          </div>
        </div>

        <div class="cols">
          <h5
            class="flex justify-center items-center text-[#002349] text-lg font-opensansbold"
          >
            <span class="px-4"> ODER </span>
          </h5>
        </div>

        <div class="cols relative">
          <label
            for="idp"
            class="text-[#002349] text-xl md:text-3xl font-opensans_semibold"
          >
            IDP suchen:
          </label>
          <div class="flex relative items-center justify-center">
            <div class="w-full">
              <v-select
                v-model="searchText"
                class="adjusted-style h-10 pd-r my-4 md:h-16 md:w-96"
                :options="allIdpData"
              />
            </div>
          </div>
        </div>

        <div class="cols">
          <button
            type="submit"
            class="w-full my-4 md:py-4 bg-[#326BAE] border-[#364652] text-white text-xl font-opensans_semibold md:text-2xl py-2 px-4 rounded z-10 hover:shadow-xl active:scale-95 active:transition-all"
            @click.prevent="submit()"
          >
            Anmelden
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { ref } from 'vue'
import axiosinstance from '../services/axiosInstance.js'
import cookie from 'cookie'

const scrollContainer = ref(null)

export default {
  name: 'Login_view',
  components: {},
  data() {
    return {
      searchText: '',
      searchEmail: '',
      openDropDown: false,
      pointer: null,
      clientSearched: [],
      userIdpSearched: [],
      idpSuggestionBox: false
    }
  },
  created() {
    this.hasError = false
    axiosinstance
      .get(`/oidc/auth${window.location.search}`)
      .then(async (x) => {
        if (x.data.code === 303) {
          window.location = x.data.uri
        } else {
          this.uid = x.data.d.uid
          const c = cookie.parse(document.cookie)
          if (c.stick) {
            try {
              const stick = JSON.parse(c.stick)
              let send = false

              if (stick.mail) {
                send = true
                this.searchEmail = stick.mail
              }

              if (stick.idp) {
                send = true
                this.searchText = stick.idp
              }

              if (send) {
                this.submit()
              }
            } catch (e) {}
          }
        }
      })
      .catch((x) => {
        this.hasError = true
        this.error =
          'Ein interner Fehler ist aufgetreten. Bitte kontaktieren Sie einen Admin.'

        console.log(x)
        if (x?.response?.status && x.response.status < 500) {
          this.hasError = true
          this.error =
            /<strong>error_description<\/strong>: ([^<]+)<\/pre>/.exec(
              x.response.data
            )

          if (this.error[1]) {
            this.error = this.error[1]
          } else {
            this.error = x?.response?.data
          }
        }
      })
    this.getAllIdps().then(() => {
      this.clientSearched
    })
  },
  methods: {
    ...mapActions({ getAllIdps: 'userSearch/getAllIdp' }),
    async submit() {
      const payload = {
        mail: this.searchEmail.trim()
          ? `a@${this.searchEmail.split('@', 2)[1]}`
          : '',
        idp: this.searchText
      }
      const r = await axiosinstance.post(
        `/oidc/interaction/${this.uid}/federated`,
        payload
      )
      if (r.data.code === 302) {
        const d = new Date()
        d.setTime(d.getTime() + 3600 * 1000)

        document.cookie = cookie.serialize('hang', JSON.stringify(payload), {
          path: '/',
          maxAge: 3600
        })

        window.location = r.data.redirectUrl
      }
    },
    handleIdpKeyUp() {
      if (this.searchText.length > 0) {
        const filteredData = this.userIdpSearched.filter((item) => {
          const itemName = item.idp.toLowerCase()
          return itemName.includes(this.searchText)
        })

        if (filteredData.length > 0) {
          this.userIdpSearched = filteredData
        } else {
          this.userIdpSearched = []
        }
      }
    },
    handleIdpSuggestionClick(suggestion) {
      if (suggestion) {
        this.openDropDown = false
        this.searchText = suggestion.idp
        this.idpSuggestionBox = false
      }
    },
    handleBlur(e) {
      const currentTarget = e.currentTarget

      setTimeout(() => {
        if (!currentTarget.contains(document.activeElement)) {
          this.idpSuggestionBox = false
        }
      }, 50)
    },
    showSuggestions() {
      this.openDropDown = false
      this.idpSuggestionBox = true
    }
  },
  computed: {
    ...mapGetters({ allIdpData: 'userSearch/allIdpsData' })
  },
  mounted() {},
  watch: {
    idpResponse: {
      handler(newVal) {
        if (newVal.code === 200) {
          this.searchText = newVal.users[0].idp
        }
      },
      deep: true
    },
    allIdpData: {
      handler(newVal) {
        this.userIdpSearched = newVal
      },
      handler(newVal) {
        if (newVal === '') {
          this.userIdpSearched = this.allIdpData
        }
      },
      deep: true
    }
  }
}
</script>

<style>
.adjusted-style .vs__search::placeholder,
.adjusted-style .vs__dropdown-toggle,
.adjusted-style .vs__dropdown-menu {
  height: 4rem;
  border-radius: 0.25rem;
  --tw-border-opacity: 1;
  border-color: rgb(151 151 151 / var(--tw-border-opacity));
  border-width: 3px;
}

#vs1__listbox {
  height: 215px;
}

.adjusted-style .vs__clear,
.adjusted-style .vs__open-indicator {
  fill: #394066;
}
</style>

<style scoped>
.svg-1 {
  top: 52%;
}

.cols {
  padding-top: 2%;
  padding-bottom: 2%;
}

.w {
  width: inherit;
}

h5::before,
h5::after {
  content: '';
  display: block;
  flex-grow: 1;
  height: 1px;
  background: rgb(194, 194, 194);
  border-radius: 50%;
}

.item {
  border-bottom: 1px solid #f2f1f1;
}

.item:last-child {
  border-bottom: none;
}

.custom-scrollbar {
  /* Set the height and width of the scrollable container */
  /* height: 300px; */
  width: 100%;
  /* Example width */
  overflow-y: auto;
  /* Enable vertical scrolling */
  overflow-x: hidden;
  /* Hide horizontal scrollbar */
  scrollbar-width: thin;
  /* Thin scrollbar */
}

/* Customize scrollbar track */
.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
  /* Example width */
}

/* Customize scrollbar thumb */
.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Example color */
  border-radius: 4px;
  /* Example border radius */
}
</style>
